<template>
  <div class="bg">
    <div class="WordSection1">
      <p
        class="MsoNormal"
        align="center"
        style="margin-bottom: 12pt; text-align: center; line-height: normal"
      >
        <span
          lang="EN-IN"
          style="font-size: 30pt; font-family: 'Helvetica Neue'; color: #262626"
          >Moonfrog Distributor Terms</span
        >
      </p>

      <p
        class="MsoNormal"
        style="margin-bottom: 30pt; text-align: justify; line-height: normal"
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >May 9, 2021</span
        >
      </p>

      <h2 style="text-align: justify">
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >Introduction</span
        >
      </h2>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 12pt;
          margin-left: 0in;
          text-align: justify;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >These Moonfrog Distributor Terms form a legally binding contract
          between you and Moonfrog, are bound by the&nbsp;<a
            href="https://www.moonfroglabs.com/terms-of-service/"
            ><span style="color: #3cb2e2">Terms of Service</span></a
          >
          and&nbsp;<a href="https://www.moonfroglabs.com/privacy-policy/"
            ><span style="color: #3cb2e2">Privacy Policy</span></a
          >
          of the Teen Patti Gold HTML5 game of Moonfrog Labs Private Limited
          (<i>“Moonfrog”</i>).</span
        >
      </p>

      <h2 style="text-align: justify">
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >1. Definitions</span
        >
      </h2>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >&quot;Face Amount&quot;</span
          ></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          means, with respect to the Virtual Currency of HTML5 Game, the
          pre-determined amount of Virtual Currency an end user is entitled to
          receive in their Teen Patti Login Id.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 0in;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >“HTML5 Game”
          </span></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >shall mean the HTML 5 version (web version) of the game teen patti
          gold available at teenpattigold.com.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.55pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >&quot;Marketing Assets&quot;</span
          ></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          means any materials provided by Moonfrog to distributor for use in
          Promotional activities.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.45pt;
          margin-right: 8.6pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >&quot;Promotional Materials&quot;</span
          ></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          means any advertising, marketing or other promotional materials for
          the HTML5 Game of Virtual Currency available at
          <a href="http://www.teenpattigold.com">www.teenpattigold.com</a
          >.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.45pt;
          margin-right: 10.3pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >&quot;Permitted Resellers&quot;</span
          ></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          means only one-level below cyber cafes, third-party Internet sites and
          other resellers to which Distributor wishes to sell Virtual Currency
          for the purpose of resale to end- users in the Territory for such
          end-users' personal use. Distributor should NOT have more than one
          level of permitted reseller below him. Such permitted resellers’ local
          government id proof needs to be shared with Moonfrog within 48 hours
          of them being added to the network. Failure to do so will lead to
          suspension of the reseller’s id. Failure to do so on more than one
          occasion will lead to suspension of the distributor’s id.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.15pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >&quot;Territory&quot;</span
          ></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          means Republic of India.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.15pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 1.85pt;
          margin-right: 23.95pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >“Virtual Currency”
          </span></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >means game currency of the HTML 5 Game termed as
          <i>Teen Patti Chips</i> in the game.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 1.85pt;
          margin-right: 23.95pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >&nbsp;</span
          ></i
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 1.85pt;
          margin-right: 23.95pt;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <i
          ><span
            lang="EN-IN"
            style="font-family: 'Helvetica Neue'; color: #262626"
            >“Wholesale Price&quot;</span
          ></i
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
        >
          means the price paid by the Distributor to purchase Virtual Currency
          of its HTML5 Game from Moonfrog, may be expressed as a discount off of
          the Face Amount of such Virtual Currency, which details are set forth
          in the Distributor Form.</span
        >
      </p>

      <h2 style="text-align: justify">
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >2</span
        ><span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >. LICENSED RIGHTS</span
        >
      </h2>

      <p
        class="MsoListParagraph"
        align="left"
        style="
          margin-left: 0in;
          text-align: left;
          text-indent: 0in;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >a.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Licenses</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.8pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          margin-bottom: 0.0001pt;
          text-indent: -3.3pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >(i)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Distribution: Moonfrog hereby grants to Distributor, and Distributor
          hereby accepts from Moonfrog, the non-exclusive, non-assignable right
          and license to distribute (directly and indirectly) and sell its
          Virtual Currency of the HTML 5 Game only on a standalone basis and not
          bundled with any other products, solely to end-users in the Territory
          for such end users' personal use, or to Permitted Resellers in the
          Territory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.8pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          margin-bottom: 0.0001pt;
          text-indent: -3.3pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >(ii)<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Marketing: Moonfrog hereby grants to Distributor and Distributor
          hereby accepts from Moonfrog, the non-exclusive, right to use, display
          and distribute any Marketing Assets, if any, provided by Moonfrog to
          Distributor, in the territory, as part of its Promotional Materials
          and solely in relation to marketing Virtual Currency. Distributor's
          license to reproduce and distribute Marketing Assets under this point
          (ii) is subject to Moonfrog's prior approval of any Promotional
          Materials.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >b.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Reservation of Rights: Moonfrog reserves all other rights not granted
          to Distributor hereunder including, without limitation, rights to
          distribute and/or sell its Virtual Currency of HTML5 Game via any
          channels of its choice in the Territory, including but not limited to
          Moonfrog's distribution system, other online or electronic delivery
          methods, OEMs or commercial use licenses and other third party
          distributors.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.85pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >c.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Distribution Controls: The Distributor shall only distribute the
          Virtual Currency of the HTML5 Game to end users of the
          Territory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.1pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 23.05pt;
          margin-bottom: 0.0001pt;
          text-indent: -15.5pt;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >d.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Trademark License</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.1pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 35.45pt;
          margin-bottom: 0.0001pt;
          text-indent: -12.4pt;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >(i)<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >License: Moonfrog grants to Distributor a non-exclusive,
          non-transferable, worldwide royalty- free license to use the Moonfrog
          trademarks, wherever provided and permitted, solely as part of
          Promotional Materials and solely in connection with the marketing,
          sale and distribution of Virtual Currency of HTML5 Game in the
          Territory in accordance with the terms and conditions of this
          Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.1pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 35.45pt;
          margin-bottom: 0.0001pt;
          text-indent: -12.4pt;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >(ii)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Proper Use: Distributor shall not use Moonfrog trademarks in
          connection with any activity that (a) disparages Moonfrog or its
          products or services; (b) violates or infringes any intellectual
          property of Moonfrog; (c) violates any applicable material local,
          state, federal, country or international regulation or law including
          applicable rules and regulations under Foreign Exchange Management
          Act, 1999 (FEMA);&nbsp;The Foreign Account Tax Compliance Act (FATCA);
          or (d) dilutes the value, in commerce, of any such Moonfrog Trademark.
          Without prior written consent from Moonfrog, Distributor shall not use
          any Moonfrog Trademark in association with any third party trademarks
          in a manner that might reasonably suggest co-branding or otherwise
          create potential confusion as to the source or sponsorship of the
          Virtual Currency of the HTML5 Game.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.1pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 35.45pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.15pt;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >(iii)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Ownership: Distributor will not claim ownership of any of the
          Moonfrog trademarks, use any of the Moonfrog trademarks as part of
          Distributor's tradename(s) or trademark(s), or otherwise use any of
          the Moonfrog trademarks in any manner that may give the impression
          that such Moonfrog trademarks belong to Distributor. All uses of the
          Moonfrog trademarks will accrue to the benefit of Moonfrog and
          Distributor will not acquire any rights in or to the Moonfrog
          trademarks. In the event that by operation of any law or regulation of
          the Territory, Distributor acquires any right, title or interest of
          any kind in the Moonfrog Trademarks, Distributor hereby agrees that
          Moonfrog will be the beneficial owner thereof and Distributor hereby
          irrevocably and unconditionally assign such right, title or interest
          to Moonfrog. At Moonfrog's request, Distributor will immediately
          execute or deliver any instrument, document, approval or registration
          that Moonfrog may deem necessary or appropriate to perfect or protect
          Moonfrog's rights in the Moonfrog trademarks.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 6.95pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: -7.6pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          ><span style="font: 7pt 'Times New Roman'">&nbsp; </span>i.<span
            style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Infringement: Distributor will be responsible to monitor the
          Territory for infringement or misuse of the Moonfrog Trademark(s) and
          will immediately inform Moonfrog in writing of any such infringement
          or misuse of which Distributor becomes aware. Moonfrog hereby reserves
          the exclusive right to introduce and pursue suits and/or actions
          against any such infringement and Distributor agrees to fully
          cooperate with Moonfrog in pursuing such suits and/or actions within
          the Territory.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.25pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >e.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Virtual Currency of HTML5 Game: Distributor acknowledges that an
          end-user's use of the Virtual Currency of HTML5 Game requires the end
          user to have an internet connection, agree to all Virtual Currency of
          HTML5 Game user agreements, register for a Teen Patti Gold account and
          have a valid and unique user ID to play the game.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >f.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >No Changes to Virtual Currency: Distributor shall not distribute the
          Virtual Currency of HTML5 Game subject to any terms or agreement that
          purports to (a) bind Moonfrog in any way or (b) vary the terms of the
          user agreement except as expressly authorized by Moonfrog in its sole
          discretion and in writing or (c) in contravention of the applicable
          laws including but not limited to applicable regulations under
          FEMA.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <h2 style="text-align: justify">
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >3. OBLIGATIONS OF DISTRIBUTOR</span
        >
      </h2>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 25.6pt;
          margin-bottom: 0.0001pt;
          text-indent: -0.25in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >a.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Sales, Marketing and Promotion: Distributor will use its best efforts
          to maximize sales of the Virtual Currency of HTML5 Game. Distributor
          will, at its own expense, use its best efforts to market and promote
          the sales of the Virtual Currency of HTML5 Game within the Territory,
          such efforts to include, without limitation: (i) actively and
          diligently marketing and promoting the Moonfrog’s Teen Patti Gold
          Game; and (ii) actively encouraging its sales and marketing staff to
          market and promote sale of the Virtual Currency of HTML5 Game.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 25.6pt;
          margin-bottom: 0.0001pt;
          text-indent: -0.25in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >b.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Customer Support: Distributor is responsible for all technical and
          customer support of the Virtual Currency of HTML5 Game in the
          Territory. However, for any advanced technical issues, Distributor can
          reach out to Teen Patti team to resolve it.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 25.6pt;
          margin-bottom: 0.0001pt;
          text-indent: -0.25in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >c.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Compliance: The Distributor shall at all times be in compliance with
          all applicable laws relating to this Agreement including but not
          limited to applicable regulations under FEMA and Foreign Account Tax
          Compliance Act and any aw applicable in the Territory. Without
          prejudice to the generality of the obligation under this sub-section,
          the Distributor agrees that it shall carry out the activities under
          this Agreement, including sale of Virtual Currency of HTML5 Game in
          the Territory and &nbsp;receipt of consideration from the Territory,
          strictly in compliance with the provisions of FEMA including Foreign
          Exchange Management (Manner of Receipt and Payment) Regulations, 2000;
          Foreign Exchange Management (Export of goods and services)
          Regulations, 2000 and Foreign Exchange Management (Current Account
          Transactions) Rules, 2000. The Distributor hereby agrees that it shall
          be solely liable for any contravention or default in compliance with
          such applicable laws and shall keep Moonfrog and all its affiliates,
          directors, employees, officers and agents harmless and indemnified
          against any loss or claim arising out of such default and/or
          non-compliance by the Distributor or any agent or representative
          engaged by it.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 25.6pt;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <h1
        style="
          margin: 0in;
          text-align: justify;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >4. PAYMENT FOR AND DELIVERY OF VIRTUAL CURRENCY</span
        >
      </h1>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.2pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >a.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Purchase of Virtual Currency of HTML5 Game: On an ongoing basis
          throughout the term of this Agreement, Distributor may request its
          desired quantity, in Face Amount as defined in the Distributor Form
          and remit to Moonfrog the corresponding payment for the Wholesale
          Price for such Virtual Currency. Within a reasonable time (generally
          72 working hours) after receiving such payment, Moonfrog will make
          available to Distributor, electronically via the Internet the number
          of Virtual Currency for which Moonfrog has received payment.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.2pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >b.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Taxes: Distributor will be responsible for and pay all taxes imposed
          on Distributor under applicable law with respect to this Agreement and
          Distributor agrees to indemnify, defend and hold Moonfrog harmless
          from any taxes or claims, causes of action, costs (including, without
          limitation, reasonable attorneys’ fees) and any other liabilities of
          any nature whatsoever related to such taxes imposed by applicable law
          on Distributor. Distributor will report and remit to the applicable
          governmental authorities’ sales taxes, use taxes, value added taxes,
          goods and services taxes, excise taxes, customs duties, levies, import
          taxes, tariffs or other similar items that may be assessed in
          connection with sales of Virtual Currency of HTML5 Game to end-user or
          Permitted Resellers.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.2pt;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.2pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >c.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Validity of chips: Distributor agrees that Virtual Currency of HTML5
          Game granted as part of each remittance shall be sold to end user/
          reseller within the dates as mentioned in the Distributor Form from
          grant of such Virtual Currency of HTML5 Game. No refund will be made
          for unsold/ un-utilised chips.</span
        >
      </p>

      <h1
        style="
          margin: 0in;
          text-align: justify;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </h1>

      <h1
        style="
          margin: 0in;
          text-align: justify;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >5. TERM AND TERMINATION</span
        >
      </h1>

      <h1
        style="
          margin: 0in;
          text-align: justify;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >&nbsp;</span
        >
      </h1>

      <h1
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          text-align: justify;
          text-indent: -14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >a.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >Term: This Agreement shall commence on the Effective Date (date of
          signing the Distributor Form) and continue in full force and effect
          for a period of 1(one) year auto renewable for another 1 (one) year,
          until terminated by either party.</span
        >
      </h1>

      <h1
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          text-align: justify;
          text-indent: -14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >b.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >Termination for Convenience: Either party may terminate this
          Agreement for convenience at any time without cause by providing ten
          (10) days' written notice to the other party. In addition, Moonfrog
          may terminate this Agreement immediately in the event of material
          breach.</span
        >
      </h1>

      <h1
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 21.3pt;
          text-align: justify;
          text-indent: -14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >c.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            font-family: 'Helvetica Neue';
            color: #262626;
            font-weight: normal;
          "
          >Effect of Termination: Upon termination of this Agreement, the
          following provisions shall apply.</span
        >
      </h1>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.8pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 42.55pt;
          margin-bottom: 0.0001pt;
          text-indent: -13.7pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(i)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Unless Moonfrog terminates this Agreement for Distributor's breach,
          Distributor may continue to use the Promotional Materials and
          distribute and sell the Virtual Currency of HTML5 Game it has
          previously purchased until such Virtual Currency of HTML5 Game are all
          sold, in which case all obligations and limitations imposed on
          Distributor in this Agreement with respect to such activities shall
          survive during the continuation of such activities. If Moonfrog
          terminates this Agreement for Distributor's breach, Moonfrog may, at
          its option, either allow Distributor to continue to sell some or all
          remaining Virtual Currency of HTML5 Game, or forfeit all previously
          purchased Virtual Currency of HTML5 Game without any refund of the
          amount paid for such Virtual Currency.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.15pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 42.55pt;
          margin-bottom: 0.0001pt;
          text-indent: -21.25pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(ii)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Except as described in (i), Distributor shall cease all use of any
          Moonfrog trademarks and any Promotional Materials.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 42.55pt;
          margin-bottom: 0.0001pt;
          text-indent: -21.25pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(iii)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >In addition to the terms which survive termination as described in
          subsection (i) above, the continuing rights and obligations under this
          Agreement will survive termination.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 42.55pt;
          margin-bottom: 0.0001pt;
          text-indent: -21.25pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(iv)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >No Damages for Termination: BOTH PARTIES AGREE THAT NEITHER PARTY
          WILL BE LIABLE TO THE OTHER PARTY FOR DAMAGES OF ANY KIND, INCLUDING,
          WITHOUT LIMITATION, ANY CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES,
          ON ACCOUNT OF TERMINATION OF THIS AGREEMENT IN ACCORDANCE WITH THIS
          SECTION 5, EVEN IF APPRAISED OF THE LIKELIHOOD OF SUCH DAMAGES
          OCCURRING.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.35pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 42.55pt;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <h1
        style="
          margin-top: 1.85pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
          text-align: justify;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >6. REPRESENTATIONS AND WARRANTIES</span
        >
      </h1>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.95pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 14.2pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.2pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >a. Moonfrog's Representations and Warranties: Moonfrog represents and
          warrants to Distributor all of the following:</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.7pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.15pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(i)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Power and Authority: Moonfrog has full power and authority to grant
          the licenses granted pursuant hereto, and to enter into, this
          Agreement and perform its obligations hereunder. This Agreement has
          been duly authorized, executed and delivered by Moonfrog and
          constitutes a valid, binding and enforceable agreement of
          Moonfrog.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.7pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.15pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(ii)<span style="font: 7pt 'Times New Roman'"> </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Rights: Moonfrog has all necessary rights, title and interest in and
          to the Virtual Currency to grant Distributor the rights granted
          hereunder and Moonfrog has not previously and will not grant any
          rights in the Virtual Currency to any third party that are
          inconsistent with the grant of rights to Distributor under this
          Agreement.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.7pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.15pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 10pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >(iii)<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT, MOONFROG
          DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
          WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS
          FOR A PARTICULAR PURPOSE WITH RESPECT TO THE VIRTUAL CURRENCY AND
          OTHER INTELLECTUAL PROPERTY DELIVERED TO DISTRIBUTOR UNDER THIS
          AGREEMENT, THE SAME BEING PROVIDED TO DISTRIBUTOR IN &quot;AS IS&quot;
          CONDITION, AND ALSO ANY IMPLIED WARRANTIES ARISING FROM COURSE OF
          DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR AGAINST
          INFRINGEMENT.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.15pt;
          margin-right: 8.1pt;
          margin-bottom: 0in;
          margin-left: 25.6pt;
          margin-bottom: 0.0001pt;
          text-indent: -0.25in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >b.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Distributor's Representations and Warranties: Distributor represents
          and warrants to Moonfrog and Distributor covenants to Moonfrog during
          the Term of this Agreement that: (a) it has full power and authority
          necessary to enter into this Agreement and perform its obligations
          hereunder; (b) this Agreement has been duly authorized, executed and
          delivered by Distributor and constitutes a valid, binding and
          enforceable agreement of Distributor; (c) Distributor will comply with
          all applicable laws and regulations in performing hereunder; (d)
          Distributor will not distribute or market any Virtual Currency of
          HTML5 Game in territories where the distribution or marketing of such
          Virtual Currency of HTML5 Game violates any applicable laws and
          regulations in such territories; and (e) Distributor shall not make
          any statements, representations or warranties about the Virtual
          Currency of HTML5 Game that are inconsistent with Moonfrog's
          descriptions thereof or that purport to bind Moonfrog.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.15pt;
          margin-right: 8.1pt;
          margin-bottom: 0in;
          margin-left: 25.6pt;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.2pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          punctuation-wrap: simple;
        "
      >
        <b
          ><span
            lang="EN-IN"
            style="
              font-size: 12pt;
              font-family: 'Helvetica Neue';
              color: #262626;
            "
            >7. INDEMNIFICATION</span
          ></b
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 8.9pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >a.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Moonfrog Indemnification: Moonfrog shall indemnify, defend and hold
          Distributor harmless from and against any and all third party claims,
          losses, liabilities, judgments and expenses (including reasonable
          attorneys' fees) resulting from or attributable to actual or alleged
          violation of any representations or warranties set forth in Section
          6(a).</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.85pt;
          margin-right: 8.35pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >b.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Distributor Indemnification: Distributor shall indemnify, defend and
          hold Moonfrog harmless from and against any and all direct or indirect
          third party claims, losses, liabilities, judgments and expenses
          (including reasonable attorneys' fees) resulting from or attributable
          to actual or alleged violation of any representations or warranties
          set forth in Section 6(b) or (ii) breach of any other obligation under
          this Agreement or (iii) is caused by any wrongful or negligent act or
          wilful misconduct by the Distributor.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.7pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-indent: 0in;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >c.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Indemnified Actions: If any action shall be brought against one of
          the parties hereto in respect to which indemnity may be sought against
          the other party (the &quot;Indemnifying Party&quot;) pursuant to
          Sections 6(a) or 6(b) above, the Indemnifying Party's obligation to
          provide such indemnification will be conditioned on prompt notice of
          such claim (including the nature of the claim and the amount of
          damages and nature of other relief sought) being provided to the
          Indemnifying Party by the party against which such action is brought
          (the &quot;Indemnified Party&quot;). The Indemnified Party shall
          cooperate with the Indemnifying Party in all reasonable respects in
          connection with the defence of any such action at the expense of the
          Indemnifying Party. The Indemnifying Party may, upon written notice to
          the Indemnified Party, undertake to conduct all proceedings or
          negotiations in connection with the action, assume the defence
          thereof, including settlement negotiations in connection with the
          action, and will be responsible for the costs of such defence,
          negotiations and proceedings. The Indemnifying Party will have sole
          control of the defence and settlement of any claims for which it
          provides indemnification hereunder, provided that the Indemnifying
          Party will not enter into any settlement of such claim without the
          prior approval of the Indemnified Party, which approval will not be
          unreasonably withheld. The Indemnified Party shall have the right to
          retain separate counsel and participate in the defence of the action
          or claim; provided that the Indemnifying Party shall have no
          obligation to indemnify the Indemnified Party for such expense.</span
        >
      </p>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 7.7pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <h1
        style="
          margin-top: 0in;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 14.2pt;
          text-indent: -14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >8.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >CONFIDENTIALITY</span
        >
      </h1>

      <p
        class="MsoBodyText"
        style="
          margin-top: 8.9pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >In the course of this Agreement, it is anticipated that the parties
          will learn confidential or proprietary information about the other
          party. The parties will keep confidential this information and any
          other information which it may acquire with respect to each other’s
          business, including, but not limited to, information developed and
          relating to new products, customers, pricing, know-how, processes and
          practices, unless and until the other party consents to disclosure, or
          unless such knowledge and information otherwise becomes generally
          available to the public through no fault of the other party. The
          existence, terms and conditions of this Agreement shall be considered
          confidential information and may only be revealed to each party's
          financial advisors and legal representatives. This undertaking to keep
          information confidential will survive the termination of this
          Agreement. It is understood, however that the restrictions listed
          above shall not apply to any portion of confidential information
          which: (i) was previously known to a party without obligations of
          confidentiality; (ii) is obtained by a party after the Effective Date
          from a third party which is lawfully in possession of such information
          and not in violation of any contractual or legal obligation to such
          third party with respect to such information; (iii) is or becomes part
          of the public domain through no fault of the other party; (iv) is
          required to be disclosed by administrative or judicial action,
          provided that the party subject to such action immediately, after
          receiving notice of such action, notifies the other party of such
          action and cooperates with the other party to seek any other legal
          remedies to maintain such confidential information in confidence; or
          (v) was independently developed by a party without reference to any
          confidential information of the other party. At the termination of
          this Agreement, each party will return to the other all confidential
          information provided.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 8.9pt;
          margin-right: 8.3pt;
          margin-bottom: 0in;
          margin-left: 7.6pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 111%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >&nbsp;</span
        >
      </p>

      <h1
        style="
          margin-top: 6.55pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 14.2pt;
          text-indent: -14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >9.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >LIMITATION OF DAMAGES</span
        >
      </h1>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.45pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          punctuation-wrap: simple;
        "
      >
        <b><span lang="EN-IN" style="font-size: 7pt">&nbsp;</span></b>
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-right: 8.2pt;
          text-align: justify;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >EXCEPT FOR LIABILITY ARISING UNDER EITHER PARTY'S INDEMNIFICATION
          OBLIGATIONS SET FORTH IN SECTION 7 ABOVE OR FOR BREACHES OF
          CONFIDENTIALITY OBLIGATIONS SET FORTH IN SECTION 8 ABOVE, NEITHER
          PARTY SHALL BE LIABLE FOR INCIDENTAL, INDIRECT, SPECIAL OR
          CONSEQUENTIAL DAMAGES OF ANY KIND OR THE LOSS OF ANTICIPATED PROFITS
          ARISING FROM ANY BREACH OF THIS AGREEMENT EVEN IF THE OTHER PARTY IS
          NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER
          ANY REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-right: 8.2pt;
          text-align: justify;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >&nbsp;</span
        >
      </p>

      <h1
        style="
          margin-top: 6.55pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 14.2pt;
          text-indent: -14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >10.<span style="font: 7pt 'Times New Roman'">&nbsp; </span></span
        ><span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >GENERAL</span
        >
      </h1>

      <h1
        style="
          margin-top: 6.55pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 14.2pt;
          punctuation-wrap: simple;
          text-autospace: none;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </h1>

      <p
        class="MsoListParagraph"
        style="
          margin-top: 1.85pt;
          margin-right: 8.25pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-indent: -14.15pt;
          line-height: 111%;
          punctuation-wrap: simple;
        "
      >
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >a.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="font-family: 'Helvetica Neue'; color: #262626"
          >Notices: Any notice, demand or communication required or permitted to
          be given by any provision of this Agreement shall be deemed to have
          been sufficiently given or served for all purposes if (a) delivered
          personally, (b) deposited with a prepaid messenger, express or air
          courier or similar courier for delivery to the address set forth
          below, (c) deposited in the official certified or registered mail,
          postage prepaid to the address set forth below, or (d) transmitted by
          tele-copier or facsimile to the number specified below (with originals
          mailed the same day by official mail, postage prepaid to the address
          set forth in the Distributor).</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0.35pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
          margin-bottom: 0.0001pt;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="font-size: 12pt; font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >Notice shall be deemed to have been received (i) upon receipt in the
          case of personal delivery, (ii) three (3) days after being deposited
          in the case of messenger, express or air courier or similar courier,
          (iii) two (2) business days after the date deposited in official
          certified or registered e-mail, and (iv) the day of receipt as
          evidenced by a facsimile confirmation statement in the case of
          transmittal by facsimile. Any party may change its respective
          addresses and facsimile number by giving notice to the other party as
          provided in this Section 10(a).</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -14.15pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >b.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >Governing Law: This Agreement will be governed by and interpreted in
          accordance with the substantive laws of India, without regard to its
          conflict of laws principles. The parties agree that any dispute
          arising under this Agreement will be resolved in Bangalore within the
          state of Karnataka, India and each party expressly consents to
          exclusive jurisdiction herein.</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -14.15pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >c.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >Attorneys' Fees: In the event any litigation proceeding is brought by
          either party in connection with this Agreement, the prevailing party
          will be entitled to recover from the other party all reasonable costs,
          attorney's fees and other expenses incurred by such prevailing party
          in the litigation or arbitration proceeding provided the prevailing
          party is being adjudged the righteous party by the Court.</span
        >
      </p>

      <p class="MsoListParagraph">
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -14.15pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >d.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >Amendment: No amendment or modification of this Agreement will be
          made except by an instrument in writing signed by both parties. No
          failure of either party hereto to prosecute its right with respect to
          any single or continuing breach of this Agreement will act as a waiver
          of the right of that party to later exercise any right or remedy
          granted hereunder with respect to that same or any other breach of
          this Agreement by the other party hereto.</span
        >
      </p>

      <p class="MsoListParagraph">
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -14.15pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >e.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >Relationship: The relationship between Distributor and Moonfrog
          arising under this Agreement will be that of independent
          contractors.</span
        >
      </p>

      <p class="MsoListParagraph">
        <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626"
          >&nbsp;</span
        >
      </p>

      <p
        class="MsoBodyText"
        style="
          margin-top: 0in;
          margin-right: 20.85pt;
          margin-bottom: 0in;
          margin-left: 28.35pt;
          margin-bottom: 0.0001pt;
          text-align: justify;
          text-indent: -14.15pt;
          line-height: 110%;
          punctuation-wrap: simple;
        "
      >
        <span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >f.<span style="font: 7pt 'Times New Roman'"
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span
        ><span
          lang="EN-IN"
          style="
            font-size: 12pt;
            line-height: 110%;
            font-family: 'Helvetica Neue';
            color: #262626;
          "
          >Severability: If any provision of this Agreement is found invalid or
          unenforceable pursuant to judicial decree, such provision will be
          enforced to the maximum extent permissible and the remainder of this
          Agreement will remain in full force and effect according to its
          terms.</span
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "DistributorTnC",
  components: {},
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.bg {
  background: white;
  min-height: 100vh;
  max-width: none;
  padding: 2rem;
}
/* Font Definitions */
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
  font-family: "Helvetica Neue";
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 8pt;
  margin-left: 0in;
  line-height: 107%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
h1 {
  mso-style-link: "Heading 1 Char";
  margin-right: 0in;
  margin-left: 0in;
  font-size: 24pt;
  font-family: "Times New Roman", serif;
  font-weight: bold;
}
h2 {
  mso-style-link: "Heading 2 Char";
  margin-right: 0in;
  margin-left: 0in;
  font-size: 18pt;
  font-family: "Times New Roman", serif;
  font-weight: bold;
}
p.MsoBodyText,
li.MsoBodyText,
div.MsoBodyText {
  mso-style-link: "Body Text Char";
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 7.6pt;
  text-autospace: none;
  font-size: 10pt;
  font-family: "Calibri", sans-serif;
}
a:link,
span.MsoHyperlink {
  color: blue;
  text-decoration: underline;
}
p {
  margin-right: 0in;
  margin-left: 0in;
  font-size: 12pt;
  font-family: "Times New Roman", serif;
}
p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 7.6pt;
  text-align: justify;
  text-autospace: none;
  font-size: 12pt;
  font-family: "Calibri", sans-serif;
}
span.Heading1Char {
  mso-style-name: "Heading 1 Char";
  mso-style-link: "Heading 1";
  font-family: "Times New Roman", serif;
  font-weight: bold;
}
span.Heading2Char {
  mso-style-name: "Heading 2 Char";
  mso-style-link: "Heading 2";
  font-family: "Times New Roman", serif;
  font-weight: bold;
}
span.BodyTextChar {
  mso-style-name: "Body Text Char";
  mso-style-link: "Body Text";
  font-family: "Times New Roman", serif;
}
.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}
@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 1in 1in 1in 1in;
}
div.WordSection1 {
  page: WordSection1;
}
/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}
</style>